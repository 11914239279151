import React,{ useState, useEffect } from "react";
import Card from './../components/Card';
import Grid from './../components/Grid';
import axios from "axios";
import { Url } from './../constants/global';

const Purchases = () => {

    const [files,setFiles] = useState("");
    const [names, setNames] = useState();
    const [payment, setPayment] = useState(false);

    const handleCheck = async(e) => {
      let check = "";
      for (let i = 0; i < 8; i++) {
        let id = "d" + i;
        if(document.getElementById(id) != null && document.getElementById(id).checked) {
          if(check.length == 0)
            check = id;
          else
            check += ":" + id;
        }
      }
      setNames(check);
    }

    const handleSubmit = async(e) => {
       e.preventDefault();
       let download_url = Url + "/downloadPurchases";
       const user = sessionStorage.getItem("userObj");
       let filename = names.includes(":") ? 'file.zip' : 'file.csv';
       axios.get(download_url, {
         params: {
           'userId': JSON.parse(user).userId,
           'filenames': names
         },
         responseType: 'blob', // important
       }).then((response) => {
         alert("Download starting ...");
         const url = window.URL.createObjectURL(new Blob([response.data]));
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', filename);
         document.body.appendChild(link);
         link.click();
         link.remove();
       });
    }


    const API_URL = Url + "/purchases";

    useEffect(() => {
      const isLoggedIn = sessionStorage.getItem("userObj");

      if (isLoggedIn != null) {
        let url = API_URL + "?userId=" + JSON.parse(isLoggedIn).userId;
        axios.get(url)
          .then(data => {
            setFiles(data.data);
          })
        }
    },[])


   return (
   <Card>
     <Card.Body isContentCentered hasPaddingHorizontal hasPaddingVertical>
       <form onSubmit={handleSubmit}>
         <h3 class="heading">You have purchased the following data sets. </h3>
           {files.includes("Population") ?
              <div>
                <input type="checkbox" id="d0" value="25" onClick={handleCheck} /> <label>Basic Population Details</label>
                <br/>
              </div> : ""
           }
           {files.includes("Education") ?
              <div>
                <input type="checkbox" id="d1" value="50" onClick={handleCheck} /> <label>Education Levels</label><br/>
              </div> : ""
           }
           {files.includes("Income") ?
              <div>
                <input type="checkbox" id="d2" value="50" onClick={handleCheck}/> <label>Income Levels</label><br/>
              </div> : ""
           }
           {files.includes("Housing") ?
              <div>
                <input type="checkbox" id="d3" value="25" onClick={handleCheck}/> <label>Housing Prices</label><br/>
              </div> : ""
           }
           {files.includes("Business") ?
              <div>
                <input type="checkbox" id="d4" value="100" onClick={handleCheck} /> <label>Business Profile</label><br/>
              </div> : ""
           }
           {files.includes("Employment") ?
              <div>
                <input type="checkbox" id="d5" value="100" onClick={handleCheck}/> <label>Employment Profiles</label><br/>
              </div> : ""
           }
           {files.includes("Enrollment") ?
              <div>
                 <input type="checkbox" id="d6" value="200" onClick={handleCheck}/> <label>School Enrollment</label><br/>
              </div> : ""
           }
           {files.includes("Household") ?
              <div>
                <input type="checkbox" id="d7" value="200" onClick={handleCheck}/> <label>Household Types</label><br/>
              </div> : ""
           }
           <div class="heading2">
             Download again ? <button type="submit">Download</button>
           </div>
           </form>
           <br/>
     </Card.Body>
   </Card>
  );
}

export default Purchases;