import React from "react";
import Arrange from './../components/Arrange';
import Grid from './../components/Grid';
import GridColumn from './../components/GridColumn';
import GridRow from './../components/GridRow';

const ZipCodeDemographicDetails = (props) => {

    return (
      <div class="find-text">
      <Grid>
        {props.data[0].zipcode ? <Grid.Row tiny={12}><div class="zipcode-text">{props.data[0].zipcode} - {props.data[0].city}</div></Grid.Row> : <div></div>}
        <Grid.Row>
          <Grid.Column tiny={6} className="grid-column-properties">
            <Arrange
              align="center"
              fitStart={<img src={require('./../assets/images/people2.png')} style={{padding: 3}} alt="Population per Census 20202" width="32" height="32"/>}
              fill={<div className="section-border"><div className="section-head">Population</div>
                  <div className="section-value">{props.data[0].numOfPeopleCensus.toLocaleString()}
                  </div></div>}
            />
          </Grid.Column>
          <Grid.Column tiny={6} className="grid-column-properties">
            <Arrange
              align="center"
              fitStart={<img src={require('./../assets/images/people.png')} style={{padding: 3}} alt="Estimated Population as per 2022 ACS Survey" width="32" height="32"/>}
              fill={<div className="section-border"><div className="section-head">Population Estimate</div>
                    <div className="section-value">{props.data[0].numOfPeopleAcs.toLocaleString()}</div></div>}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column tiny={6} className="grid-column-properties">
            <Arrange
              align="center"
              fitStart={<img src={require('./../assets/images/income.png')} style={{padding: 3}} alt="Median Income" width="32" height="32"/>}
              fill={<div className="section-border"><div className="section-head">Median Income</div>
                  <div className="section-value">${props.data[0].medianIncome.toLocaleString()}</div></div>
                 }
            />
          </Grid.Column>
          <Grid.Column tiny={6} className="grid-column-properties">
            <Arrange
              align="center"
              fitStart={<img src={require('./../assets/images/income2.png')} style={{padding: 3}} alt="Mean Income" width="32" height="32"/>}
              fill={<div className="section-border"><div className="section-head">Mean Income</div>
                    <div className="section-value">${props.data[0].meanIncome.toLocaleString()}</div></div>}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column tiny={6} className="grid-column-properties">
            <Arrange
              align="center"
              fitStart={<img src={require('./../assets/images/bachelor.png')} style={{padding: 3}} alt="People with Bachelor degree or more" width="32" height="32"/>}
              fill={<div className="section-border"><div className="section-head">Education - Bachelors or More</div>
                    <div className="section-value">{props.data[0].bachelorsDegreeOrMoreOver25.toLocaleString()}</div></div>
                   }
            />
          </Grid.Column>
          <Grid.Column tiny={6} className="grid-column-properties">
            <Arrange
              align="center"
              fitStart={<img src={require('./../assets/images/house.png')} style={{padding: 3}} alt="Households" width="32" height="32"/>}
              fill={<div className="section-border"><div className="section-head">Households</div>
                    <div className="section-value">{props.data[0].numOfHouseholds.toLocaleString()}</div></div>}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      </div>
    );
};

export default ZipCodeDemographicDetails;