import React, { useState } from 'react';
import { useViewport } from 'react-viewport-hooks';
import Card from './../components/Card';
import Spotlight2 from './spotlight/Spotlight2';
import Spotlight1 from './spotlight/Spotlight1';
import Spotlight3 from './spotlight/Spotlight3';
import Spotlight4 from './spotlight/Spotlight4';
import Grid from './../components/Grid';

const GridLayout = () => {

    const [index,setIndex] = useState(4);

    const handleInput = val => async(e) => {
        e.preventDefault();
        setIndex(val);
    }

    return (
        <Grid>
          <Grid.Row>
            <Grid.Column tiny={12} small={3} medium={3} large={3}>
              <ul>
                <li class="li-news" onClick={handleInput(3)}>Roseville, CA<span class="li-new"> *New*</span></li>
                <li class="li-news" onClick={handleInput(3)}>Myrtle Beach, SC</li>
                <li class="li-news" onClick={handleInput(2)}>Hanover, PA</li>
                <li class="li-news" onClick={handleInput(1)}>Katy, TX</li>
              </ul>
            </Grid.Column>
            <Grid.Column tiny={12} small={9} medium={9} large={9}>
              { (() => {
                if(index == 1)
                   return (<Spotlight1 />)
                else if(index == 2)
                   return (<Spotlight2 />)
                else if(index == 3)
                   return (<Spotlight3 />)
                else if(index == 4)
                   return (<Spotlight4 />)
                })()
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
    );
};

const ListLayout = () => {

    return (
        <Card>
          <Card>
            <details open>
              <summary class="li-news">Roseville, CA<span class="li-new"> *New*</span></summary>
              <Spotlight4 />
            </details>
          </Card>
          <Card>
            <details>
              <summary class="li-news">Myrtle Beach, SC</summary>
                <Spotlight3 />
              </details>
          </Card>
          <Card>
            <details>
                <summary class="li-news">Hanover, PA</summary>
                  <Spotlight2 />
            </details>
          </Card>
          <Card>
            <details>
               <summary class="li-news">Katy, TX</summary>
               <Spotlight1 />
            </details>
          </Card>
        </Card>
    );
};

const Spotlight = () => {

        const { vw } = useViewport();
        console.log(vw);

        let layout = 'grid';
        if (vw < 768) {
            layout = 'list';
        }
        return (
            <div>
                {layout === 'grid' ?
                    <GridLayout /> :
                    <ListLayout />}
            </div>
        );
}

export default Spotlight;