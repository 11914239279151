import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {loadStripe} from '@stripe/stripe-js';
import axios from "axios";
import { Url, stripePromise } from './../constants/global';
import Card from './../components/Card';
import Subscribe from './Subscribe';

import {
  PaymentElement,
  Elements,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';

const options = {
  mode: 'subscription',
  amount: 1099,
  currency: 'usd',
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};

const SubscribeForm = ()  => {

  return (
    <div class="bg-image">
      <Elements stripe={stripePromise} options={options}>
        <Subscribe />
      </Elements>
    </div>
  );
};

export default SubscribeForm;