import React, { useState } from 'react';
import axios from "axios";
import { Url } from './../constants/global';
import Card from './../components/Card';

const SignUp = () => {
    // State to store the form data
    const [firstName,setFirstName] = useState('');
    const [lastName,setLastName] = useState('');
    const [userName,setUserName] = useState('');
    const [password,setPassword] = useState('');
    const [isCompany,setIsCompany] = useState('');
    const [companyName,setCompanyName] = useState('');
    const [companyEmail,setCompanyEmail] = useState('');
    const [address,setAddress] = useState('');
    const [email,setEmail] = useState('');
    const [phoneNumber,setPhoneNumber] = useState('');

    // Function to submit the form data using Axios
    const handleSubmit = async (e) => {
      e.preventDefault();
      let url = Url + "/users";
      axios.post(url, {
        'firstName': firstName,
        'lastName': lastName,
        'userName': userName,
        'password': password,
        'address': address,
        'companyName': companyName,
        'companyEmail': companyEmail,
        'isCompany': isCompany,
        'email': email,
        'phoneNumber': phoneNumber
      },{
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      .then(function (response) {
        sessionStorage.setItem("userObj",JSON.stringify(response.data));
        alert("Signup Successful. Go to Subscribe to get weekly news feed");
        window.location = "/";
      })
      .catch(function (error) {
        alert("User Signup failed" + error);
      });
    };

    return (
      <div class="centered-content">
        <form onSubmit={handleSubmit} class="signup-form">
            <div class="heading2"> </div><br/>
            <div class="heading2">Sign Up</div><br/>
            <text>Enter following information for signing up.<br/> ** - indicates required field.</text><br/><br/>
            <div class="form-group">
              <label>User Name<text class="label-required">* </text></label>
              <input type="text" value={userName} required
                pattern="[a-z]{8,12}"
                onChange={e => setUserName(e.target.value)}
                title="Lower Case letters 8 to 12"
                placeholder="User Name" />
            </div><br/>
            <div class="form-group">
              <label>Password<text class="label-required">* </text></label>
              <input type="password"
                value={password} required
                pattern="(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                onChange={e => setPassword(e.target.value)} />
            </div><br/>
            <div class="form-group">
              <label>First Name </label>
              <input type="text"
                value={firstName}
              onChange={e => setFirstName(e.target.value)} />
            </div><br/>
            <div class="form-group">
              <label>Last Name </label>
              <input type="text"
                value={lastName}
                onChange={e => setLastName(e.target.value)} />
            </div><br/>
            <div class="form-group">
              <label>Email Address<text class="label-required">* </text></label>
              <input type="text" required
                value={email} onChange={e => setEmail(e.target.value)} />
            </div><br/>
            <div class="form-group">
              <label>Phone Number </label>
              <input type="text"
                type="text" pattern="[0-9]{9,9}"
                value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
            </div><br/>
            <div class="form-group">
              <label>Company Name </label>
              <input type="text"
                        value={companyName} onChange={e => setCompanyName(e.target.value)} />
            </div><br/>
            <div class="form-group">
              <label>Company Email </label>
              <input type="text"
                type="text"
                value={companyEmail} onChange={e => setCompanyEmail(e.target.value)} />
            </div><br/>
          <button type="submit" className="btn btn-primary btn-block">Sign Up</button>
        </form>
    </div>
  );
}

export default SignUp;