import React from 'react';
import styles from './../assets/css/card.css';
import CardBody from './CardBody';

const Card = ({
    children
   }) => {

   return (
    <article className="card raised vertical-padding horizontal-padding">
      {children}
    </article>
  );
};

Card.Body = CardBody;
export default Card;