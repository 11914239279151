import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  /**
   * The component that will be displayed at column level.
   */
  children: PropTypes.node.isRequired,
  /**
   * Custom class name that can be assigned to grid row.
   */
  className: PropTypes.string,
};

const GridRow = ({ className, children, ...customProps }) => {

  return (
    <div {...customProps} className="grid row">
      {children}
    </div>
  );
};

GridRow.propTypes = propTypes;

export default GridRow;