import React, { useState } from "react";
import axios from "axios";
import Card from './../components/Card';
import Grid from './../components/Grid';
import GridColumn from './../components/GridColumn';
import GridRow from './../components/GridRow';
import './../assets/css/chat.css';
import { Url } from './../constants/global';
import qs from 'qs';

const ChatWindow = () => {
  // State to store the form data
  const [prompts, setPrompts] = useState([]);
  const [input, setInput] = useState();
  const [count,setCount] = useState(0);
  const [ip, setIP] = useState();
  const [spinner, setSpinner] = useState(false);

  const getData = async () => {
      const res = await axios.get("https://api.ipify.org/?format=json");
      console.log(res.data);
      setIP(res.data.ip);
  };

  // Function to submit the form data using Axios
  const handleSubmit = async (e) => {
    e.preventDefault();
    var currPrompts = prompts.slice();
    currPrompts.push({'input': input});
    var inputParams = currPrompts.map((filter) => filter.input);
    var outputParams = prompts.map((filter) => JSON.stringify(filter.output));
    let url = Url + "/promptQuery";
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      console.log(res.data);
      setIP(res.data.ip);
    } catch (error) {
      console.log(error);
    }

    const URLString = inputParams.map((value, index) => `input=${value}`).join('&');

    url += "?" + URLString;

    setSpinner(true);
    try {
      axios.get(url, {
        headers: {
            'X-Custom-Header': `${ip}`,
        }
      })
        .then(function (response) {
          currPrompts[count].output = response.data;
          setPrompts(currPrompts);
          setCount(count+1);
          setSpinner(false);
        })
        .catch(function (error) {
          setSpinner(false);
          alert("Error occurred while retrieving chat output");
        });

    } catch (error) {
      setSpinner(false);
      alert("Error occurred while retrieving chat output");
    }
  };

  return (
        <Card>
          <div class="chat-text">
            Analyze with your own questions <br/>
          </div>
          <div class="chat-sub-text">
            "Give me zip code with highest mean income" <br/>
            "Zip code with highest median house price" <br/>
          </div>
          <div class="chat-page">
            <div class="msg-inbox">
              { prompts.length > 0 ?
              <div class="chats">
                <div class="msg-page">
                  { prompts.map((prompt)=>{
                      return(
                        <div>
                          <div class="received-chats">
                            <div class="received-msg">
                              <div class="received-msg-inbox">
                                <p>{prompt.input}</p>
                              </div>
                            </div>
                          </div>
                          <div class="outgoing-chats">
                            <div class="outgoing-msg">
                              <div class="outgoing-chats-msg">
                                <p>
                                  {prompt.output && Object.keys(prompt.output).map((key, index)=>(
                                            <h5>{key} - {prompt.output[key]}</h5>
                                  ))}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )})
                  }
                </div>
              </div>
              : <div></div>
              }

              { spinner ? <div class="loading-icon"><img src={require('./../assets/images/loading.jpg')} width="60" height="60" /></div> : ""}

              <div class="msg-bottom">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Type your question ..."
                    onChange={e => setInput(e.target.value)}
                  />
                  <span class="input-group-text send-icon">
                    <button class="button-no-border" onClick={handleSubmit}><img src={require('./../assets/images/chat.png')} width="45" height="45" /> </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Card>
  );
};

export default ChatWindow;