import React from 'react';
import Card from './../components/Card';

const SampleFiles = () => {
  return (
    <Card>
      <Card>
      <details>
        <summary>Population Details</summary>
        Showing sample for couple of zip codes. Similar data can be downloaded for zip codes across USA.<br/>
        <p>
        <div class="heading4">
        ZipCode<br/>
        Total Population<br/>
        White<br/>
        Black<br/>
        AmericanIndian<br/>
        Asian<br/>
        HawaiiIndian<br/>
        SomeOtherRace<br/>
        TwoRacesOrMore<br/>
        Number of Households<br/>
        Urban Population<br/>
        Rural<br/>
        Occupied Homes<br/>
        Vacant Houses<br/>
        OwnedWithMortgage<br/>
        OwnedWithoutLoan<br/>
        Rented<br/>
        1-person Households<br/>
        2-person Households<br/>
        3-person Households<br/>
        4-person Households<br/>
        5-person Households<br/>
        6-person Households<br/>
        7 Or More person Households<br/></div>
      97355 32413	28117	136	412	342	78	768	2560	12917	9125	3792	12287	630	5591	2576	4120	2955	4518	1892	1494	806	346	276<br/>
      24412 106	101	2	0	0	0	0	3	80	0	80	47	33	7	22	18	21	16	6	4	0	0	0<br/>
      97357	307	242	0	20	1	1	7	36	137	0	137	115	22	67	47	1	15	52	16	24	1	4	3<br/>
      </p>
      </details>
      </Card>
      <Card>
      <details>
        <summary>Education Details</summary>
        <p>
        <div class="heading4">
        Zip Code<br/>
        Year<br/>
        Population 18 to 24<br/>
        -- Less than High School<br/>
        -- High School Graduate<br/>
        -- Some College or Associates <br/>
        -- Bachelors or more<br/>
        Population 25 or over<br/>
        -- Less than 9th grade<br/>
        -- 9th - 12th grade no diploma<br/>
        -- High School Graduate <br/>
        -- Some College <br/>
        -- Associates Degree <br/>
        -- Bachelors Degree <br/>
        -- Graduate or Professional Degree <br/>
        -- High School Graduate or Over <br/>
        -- Bachelors or Over <br/>
        Male - Population 18 to 24 <br/>
        -- Male 18 to 24- Less than High School <br/>
        -- Male 18 to 24- High School Graduate <br/>
        -- Male 18-24 - Some College or Associates <br/>
        -- Male 18 to 24- Bachelors or more,
        Male - Population 25 or over <br/>
        -- Male 25+ - Less than 9th grade <br/>
        -- Male 25+ 9th - 12th grade no diploma <br/>
        -- Male 25+ - High School Graduate <br/>
        -- Male 25+ - Some College <br/>
        -- Male 25+ - Associates Degree <br/>
        -- Male 25+ - Bachelors Degree <br/>
        -- Male 25+ - Graduate or Professional Degree <br/>
        -- Male 25+ - High School Graduate or Over <br/>
        -- Male 25+ - Bachelors or Over <br/>
        Female - Population 18 to 24 <br/>
        -- Female 18 to 24- Less than High School <br/>
        -- Female 18 to 24- High School Graduate <br/>
        -- Female 18-24 - Some College or Associates <br/>
        -- Female 18 to 24- Bachelors or more <br/>
        Female - Population 25 or over<br/>
        -- Female 25+ - Less than 9th grade <br/>
        -- Female 25+ 9th - 12th grade no diploma <br/>
        -- Female 25+ - High School Graduate <br/>
        -- Female 25+ - Some College <br/>
        -- Female 25+ - Associates Degree <br/>
        -- Female 25+ - Bachelors Degree <br/>
        -- Female 25+ - Graduate or Professional Degree <br/>
        -- Female 25+ - High School Graduate or Over <br/>
        -- Female 25+ - Bachelors or Over <br/></div>

        97351,2022,1985,133,769,851,232,6418,520,345,1632,2225,301,985,410,5553,1395,1144,124,616,322,82,3393,393,220,904,1127,149,427,173,2780,600,841,9,153,529,150,3025,127,125,728,1098,152,558,237,2773,795<br/>
        97351,2021,1626,172,642,625,187,6243,362,464,1567,2325,280,830,415,5417,1245,1131,150,539,340,102,3341,270,258,884,1261,122,338,208,2813,546,495,22,103,285,85,2902,92,206,683,1064,158,492,207,2604,699<br/>
        97351,2020,1942,141,835,740,226,6276,403,447,1554,2367,351,745,409,5426,1154,1148,116,561,408,63,3182,236,274,837,1184,208,257,186,2672,443,794,25,274,332,163,3094,167,173,717,1183,143,488,223,2754,711<br/>
        </p>
      </details>
      </Card>
      <Card>
      <details>
        <summary>Employment Details</summary>
        <p>
        <div class="heading4">
        ZipCode<br/>
        Year<br/>
        Population with Age > 16 <br/>
        -- Population between ages 16 and 19<br/>
        -- Population between ages 20 and 24<br/>
        -- Population between ages 25 and 29<br/>
        -- Population between ages 30 and 34<br/>
        -- Population between ages 35 and 44<br/>
        -- Population between ages 45 and 54<br/>
        -- Population between ages 55 and 60<br/>
        -- Population between ages 60 and 64<br/>
        -- Population between ages 65 and 75<br/>
        -- Population with Age > 75 <br/>
        Labor Rate for Age > 16 <br/>
        -- Labor Rate between ages 16 and 19<br/>
        -- Labor Rate between ages 20 and 24<br/>
        -- Labor Rate between ages 25 and 29<br/>
        -- Labor Rate between ages 30 and 34<br/>
        -- Labor Rate between ages 35 and 44<br/>
        -- Labor Rate between ages 45 and 54<br/>
        -- Labor Rate between ages 55 and 60<br/>
        -- Labor Rate between ages 60 and 64<br/>
        -- Labor Rate between ages 65 and 75<br/>
        -- Labor Rate for Age > 75<br/></div>
        97358	2022	2098	61	185	92	120	198	357	167	196	588	134	63.4	54.1	93.0	100.0	90.8	91.9	86.3	60.5	75.5	29.9	6.7<br/>
        97358	2021	2150	50	157	73	83	162	314	205	251	708	147	59.1	54.0	94.9	100.0	86.7	92.6	79.6	58.5	72.1	29.0	29.3<br/>
        97358	2020	2322	80	178	68	75	222	376	266	287	588	182	59.4	71.3	94.9	100.0	97.3	93.2	81.1	59.8	65.9	17.5	26.9<br/>
        </p>
      </details>
      </Card>
      <Card>
      <details>
        <summary>Income Characteristics</summary>
        <p>
        <div class="heading4">
        Zip Code<br/>
        Year<br/>
        Total Households<br/>
        Percentage Income Less than 10000 <br/>
        Percentage Income between 10,000 and 14,999 <br/>
        Percentage Income between 15,000 and 24,999 <br/>
        Percentage Income between 25,000 and 34,999 <br/>
        Percentage Income between 35,000 and 49,999 <br/>
        Percentage Income between 50,000 and 74,999 <br/>
        Percentage Income between 75,000 and 99,999 <br/>
        Percentage Income between 100,000 and 149,999 <br/>
        Percentage Income between 150,000 and 199,999 <br/>
        Percentage Income greater than 200,000<br/>
        Median Income<br/>
        Mean Income<br/>
        Families - Total Households<br/>
        Families - Percentage Income Less than 10000 <br/>
        Families - Percentage Income between 10,000 and 14,999 <br/>
        Families - Percentage Income between 15,000 and 24,999 <br/>
        Families - Percentage Income between 25,000 and 34,999 <br/>
        Families - Percentage Income between 35,000 and 49,999 <br/>
        Families - Percentage Income between 50,000 and 74,999 <br/>
        Families - Percentage Income between 75,000 and 99,999 <br/>
        Families - Percentage Income between 100,000 and 149,999 <br/>
        Families - Percentage Income between 150,000 and 199,999 <br/>
        Families - Percentage Income greater than 200,000<br/>
        Families - Median Income<br/>
        Families - Mean Income<br/>
        Married Families - Total Households<br/>
        Married Families - Percentage Income Less than 10000 <br/>
        Married Families - Percentage Income between 10,000 and 14,999 <br/>
        Married Families - Percentage Income between 15,000 and 24,999 <br/>
        Married Families - Percentage Income between 25,000 and 34,999 <br/>
        Married Families - Percentage Income between 35,000 and 49,999 <br/>
        Married Families - Percentage Income between 50,000 and 74,999 <br/>
        Married Families - Percentage Income between 75,000 and 99,999 <br/>
        Married Families - Percentage Income between 100,000 and 149,999 <br/>
        Married Families - Percentage Income between 150,000 and 199,999 <br/>
        Married Families - Percentage Income greater than 200,000<br/>
        Families - Median Income<br/>
        Families - Mean Income<br/>
        Non Families - Total Households<br/>
        Non Families - Percentage Income Less than 10000 <br/>
        Non Families - Percentage Income between 10,000 and 14,999 <br/>
        Non Families - Percentage Income between 15,000 and 24,999 <br/>
        Non Families - Percentage Income between 25,000 and 34,999 <br/>
        Non Families - Percentage Income between 35,000 and 49,999 <br/>
        Non Families - Percentage Income between 50,000 and 74,999 <br/>
        Non Families - Percentage Income between 75,000 and 99,999 <br/>
        Non Families - Percentage Income between 100,000 and 149,999 <br/>
        Non Families - Percentage Income between 150,000 and 199,999 <br/>
        Non Families - Percentage Income greater than 200,000<br/>
        Non Families - Median Income<br/>
        Non Families - Mean Income<br/></div>
        97351	2022	3436	4.0	2.6	5.9	6.7	12.3	16.0	20.9	16.5	9.1	6.0	76385	85611	2427	0.6	1.3	4.1	8.4	11.1	18.4	20.3	16.9	11.5	7.4	79430	94262	1777	0.0	0.0	3.0	7.3	9.8	14.7	17.5	22.8	14.9	10.1	97431	N	1009	12.3	7.5	10.4	8.9	19.9	17.5	16.7	2.2	2.0	2.6	43317	51503<br/>
        97351	2021	3336	2.2	4.0	6.8	11.5	12.0	24.7	11.7	18.3	6.3	2.4	66962	74467	2287	1.7	1.7	3.5	14.3	11.9	23.7	11.3	20.6	9.0	2.4	70096	80306	1620	0.8	0.7	2.0	14.4	9.7	19.7	9.4	28.6	11.2	3.3	80000	N	1049	3.3	10.5	14.1	12.2	19.0	28.9	4.5	5.1	0.0	2.5	39814	50190<br/>
        97351	2020	3511	5.3	2.4	8.9	7.5	16.1	26.1	12.6	14.2	5.3	1.5	55772	68004	2465	4.7	2.2	3.5	8.4	15.0	29.4	11.1	16.6	7.5	1.7	66210	74821	1748	0.7	0.8	1.8	4.7	16.2	27.9	13.2	21.8	10.6	2.3	72179	N	1046	5.8	6.7	21.7	15.2	20.8	20.9	2.9	4.8	0.0	1.1	35234	41426<br/>
        </p>
      </details>
      </Card>
      <Card>
      <details>
        <summary>Households Types</summary>
        <p>
        <div class="heading4">
        ZipCode<br/>
        Year<br/>
        TotalHouseholds<br/>
        Married Couple<br/>
        -- Married Couple without Children<br/>
        -- Married Couple with Children<br/>
        Cohabitating Couple<br/>
        -- Cohabitating Couple without Children<br/>
        -- Cohabitating Couple with Children<br/>
        Female - No Spouse Or Partner<br/>
        -- Female Livinig Alone<br/>
        -- Female with Children Under 18<br/>
        -- Female with only relative Children<br/>
        -- Female with only Relatives<br/>
        Male - No Spouse Or Partner<br/>
        -- Male Livinig Alone<br/>
        -- Male With Children Under 18<br/>
        -- Male With Only Relative Children<br/>
        -- Male with Only Relatives<br/></div>
      97355	2021	12435	6316	1968	4348	657	299	358	3341	1939	336	749	317	2121	1446	205	321	149<br/>
      97355	2020	12014	6488	1995	4493	623	263	360	3090	1909	244	741	196	1813	1242	137	323	111<br/>
      97355	2019	11759	6473	1992	4481	598	326	272	3117	1941	401	574	201	1571	989	198	243	141
      </p>
      </details>
      </Card>
      <Card>
      <details>
        <summary>School Enrollment</summary>
        <p>
        <div class="heading4">
        Showing sample data for a zip code. Actual file has close 167,000 for across all zip codes across USA.<br/>
        ZipCode<br/>
        Year<br/>
        Pupil in School Age > 3<br/>
          In Nursery/Pre-K<br/>
          In KG-12<br/>
            -- In Kindergarten<br/>
            -- In 1st-4th grade<br/>
            -- In 5th-8th grade<br/>
            -- In 9th-12th grade<br/>
          In UnderGraduate<br/>
          In Graduate<br/>
          In College(UG/PG)<br/>
            -- Males in College<br/>
            -- Females in College<br/>
        Pupil in Public School Age > 3<br/>
          In Public School - Nursery/Pre-K<br/>
          In Public School - KG-12<br/>
            -- In Public School - Kindergarten<br/>
            -- In Public School - 1st-4th grade<br/>
            -- In Public School - 5th-8th grade<br/>
            -- In Public School - 9th-12th grade<br/>
          In Public School - Under Graduate<br/>
          In Public School - Post Graduate<br/>
          In Public College<br/>
            -- In Public College -Male<br/>
            -- In Public College - Female<br/></div>
        97355 2022    6667	220	4939	310	1551	1783	1295	1081	427	1508	552	956	(X)	146	3803	256	1122	1394	1031	1004	131	1135	472	663<br/>
        97355	2021	6428	295	4622	272	1575	1626	1149	1095	416	1511	399	1112	(X)	188	3633	242	1132	1304	955	1024	99	1123	309	814<br/>
        97355	2020	6466	355	4634	340	1578	1499	1217	1084	393	1477	474	1003	(X)	220	3796	250	1280	1271	995	1020	98	1118	377	741<br/>
        </p>
      </details>
      </Card>
      <Card>
      <details>
        <summary>Business Profiles</summary>
        <p>
        <div class="heading4">
        Zip Code<br/>
        Year<br/>
        2017 NAICS Code<br/>
        NAICS Code Meaning<br/>
        Organization Type Code (By Size)<br/>
        Organization Type Code Meaning<br/>
        Number of Establishments<br/>
        Annual Payroll in Thousands<br/>
        Number of Employees<br/></div>
        97358	2020	00	Total for all sectors	001	All establishments	47	29761	499<br/>
        19435	2021	00	Total for all sectors	001	All establishments	8	9277	274<br/>
        19435	2021	00	Total for all sectors	210	Establishments with less than 5 employees	4	N	N<br/>
        </p>
      </details>
      </Card>
      <Card>
      <details>
        <summary>Housing Data</summary>
        <p>
        <div class="heading4">
        period_begin<br/>
        period_end<br/>
        period_duration<br/>
        region_type<br/>
        region_type_id<br/>
        table_id<br/>
        is_seasonally_adjusted<br/>
        region<br/>
        city<br/>
        state<br/>
        state_code<br/>
        property_type<br/>
        property_type_id<br/>
        median_sale_price<br/>
        median_sale_price_mom<br/>
        median_sale_price_yoy<br/>
        median_list_price<br/>
        median_list_price_mom<br/>
        median_list_price_yoy<br/>
        median_ppsf<br/>
        median_ppsf_mom<br/>
        median_ppsf_yoy<br/>
        median_list_ppsf<br/>
        median_list_ppsf_mom<br/>
        median_list_ppsf_yoy<br/>
        homes_sold<br/>
        homes_sold_mom<br/>
        homes_sold_yoy<br/>
        pending_sales<br/>
        pending_sales_mom<br/>
        pending_sales_yoy<br/>
        new_listings<br/>
        new_listings_mom<br/>
        new_listings_yoy<br/>
        inventory<br/>
        inventory_mom<br/>
        inventory_yoy<br/>
        months_of_supply<br/>
        months_of_supply_mom<br/>
        months_of_supply_yoy<br/>
        median_dom<br/>
        median_dom_mom<br/>
        median_dom_yoy<br/>
        avg_sale_to_list<br/>
        avg_sale_to_list_mom<br/>
        avg_sale_to_list_yoy<br/>
        sold_above_list<br/>
        sold_above_list_mom<br/>
        sold_above_list_yoy<br/>
        price_drops<br/>
        price_drops_mom<br/>
        price_drops_yoy<br/>
        off_market_in_two_weeks<br/>
        off_market_in_two_weeks_mom<br/>
        off_market_in_two_weeks_yoy<br/>
        parent_metro_region<br/>
        parent_metro_region_metro_code<br/>
        last_updated<br/></div>
        2023-10-01	2023-12-31	90	zip code	2	40791	f	Zip Code: 98056		Washington	WA	All Residential	-1	985000	0.15882352941176481	0.23899371069182385	909950	0	0.10296969696969693	448.56704196519956	0	0.031320805885800773	448.98172323759792	0.017171347485526178	0.061921333081221608	65	-0.17721518987341767	-0.2696629213483146	15	-0.42307692307692313	-0.16666666666666663	63	-0.27586206896551724	0.10526315789473695	32	-0.11111111111111116	-0.085714285714285743				14	-3	-11	0.98508058108802243	-0.0026191460242913633	0.0036819299256777294	0.29230769230769232	-0.024148003894839343	0.10129645635263615				0.46666666666666667	0.15897435897435896	0.18888888888888888	Seattle, WA	42644	2024-05-19 14:23:50<br/>
        2021-12-01	2022-02-28	90	zip code	2	41258	f	Zip Code: 98848		Washington	WA	Townhouse	13	505500	0	0.24874631304871953	486000	-0.03857566765578635	0.23037974683544293	298.71689662846336	0	0.37754499381738693	276.13636363636363	-0.075591750071589803	0.26813003452243955	2	0	0				1	-0.5	0							4	0	0	1	0	-0.018266295665739118	0	0	-0.5							Moses Lake, WA	34180	2024-05-19 14:23:50<br/>
        2021-05-01	2021-07-31	90	zip code	2	40915	f	Zip Code: 98252		Washington	WA	All Residential	-1	558000	0.0026954177897573484	0.39060222047773907	549475	-0.00095363723057928507	0.44599064734380889	294.76787030213706	0.023977499969352278	0.27772848269742667	299.57000742188302	0.045267199252108092	0.19581261971317909	81	0.06578947368421062	0.14084507042253525	27	-0.15625	0	94	0	0.54098360655737698	28	-0.034482758620689613	1.1538461538461537				7	1	-5	1.0367051230499968	-0.0096529115384567099	0.037732658725698087	0.60493827160493829	-0.026640675763482724	0.19648756737958617				0.70370370370370372	-0.10879629629629628	-0.11111111111111105	Seattle, WA	42644	2024-05-19 14:23:50<br/>
        </p>
      </details>
      </Card>

    </Card>
  );
}

export default SampleFiles;