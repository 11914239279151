import React, { useState } from "react";
import axios from "axios";
import ZipCodePriceDetails from "./ZipCodePriceDetails";
import ZipCodeDemographicDetails from "./ZipCodeDemographicDetails";
import Grid from './../components/Grid';
import { Url } from './../constants/global';
import Card from './../components/Card';
import { useViewport } from 'react-viewport-hooks';

const FindByZipCode = () => {
  // State to store the form data

  const [details, setDetails] = useState([]);
  const [demographicDetails, setDemographicDetails] = useState([]);
  const [options, setOptions] = useState([]);
  const [spinner,setSpinner] = useState(false);

  const handleInput = async(e) => {
    e.preventDefault();
    let input = document.getElementById("prompt").value;
    let url = Url + "/getPlaces/" + input;
    if(input != null && input.length >= 3 && isNaN(input)) {
      const response = await axios.get(url);
      if(response != null && response.data != null) {
        setOptions(response.data);
      }
    } else {
      setOptions([]);
    }
  }

  // Function to submit the form data using Axios
  const handleSubmit = async (e) => {
    e.preventDefault();
    let base_url = Url + "/zipCodePriceDetails/";
    let demographic_url = Url + "/zipCodeDemographicDetails/";
    let prompt = document.getElementById("prompt").value;
    if(prompt != null) {
      prompt = prompt.trim();
      prompt = prompt.replaceAll("\"","");
      if(prompt.length < 3) {
        alert("Enter atleast 3 characters to search");
        return ;
      }
      if(isNaN(prompt)) {
        base_url = Url + "/placePriceDetails/";
        demographic_url = Url + "/placeDemographicDetails/";
      } else if(prompt.trim().length > 5) {
        alert("Enter 5 digit zip code");
        return ;
      }
      try {
        const url = base_url.concat(prompt);
        setSpinner(true);
        const response = await axios.get(url);
        if(response != null && response.data != null && response.data.length > 0)
          setDetails(<ZipCodePriceDetails data={response.data} />);
        else
          setDetails("Price Details are not available for this place");

        const demo_url = demographic_url.concat(prompt);
        const demo_response = await axios.get(demo_url);
        if(demo_response != null && demo_response.data != null && demo_response.data[0] != null)
          setDemographicDetails(<ZipCodeDemographicDetails data={demo_response.data} />);
        else
          setDemographicDetails("Demographic Details are not available for this place");
        setSpinner(false);
      } catch (error) {
        console.error("Error during Get: ", error);
      }
    } else {
      alert("Give valid zip code or place to search");
    }
  };

   let layout = 'grid';
   const { vw } = useViewport();
   if (vw < 768) {
     layout = 'list';
   }

  return (
    <Card>
    <div class="bg-img">
    <form onSubmit={handleSubmit} class="container">
      <input type="text" class="zipcode-entry" placeholder="Enter Zip Code OR City" list="suggestions" id="prompt" onChange={handleInput} />
      <datalist id="suggestions">
        <div>
          {options.map((e) => {
            return (
              <option key={e} value={e}>
                {e}
              </option>
            );
          })}
        </div>
      </datalist>&nbsp;
      <button type="submit" class="submit-button">Go</button>
    </form>
    </div>

    { spinner ? <div class="loading-icon"><img src={require('./../assets/images/loading.jpg')} width="60" height="60" /></div> : ""}

    {
    vw > 768 ? <Grid>
                     <Grid.Row>
                       <Grid.Column tiny={4}>{details}</Grid.Column>
                       <Grid.Column tiny={8}>{demographicDetails}</Grid.Column>
                     </Grid.Row>
               </Grid>
               :
               <Grid>
                  <Grid.Row>
                    <Grid.Column tiny={12}>{demographicDetails}</Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column tiny={12}>{details}</Grid.Column>
                  </Grid.Row>
               </Grid>
    }
    </Card>
  );
};

export default FindByZipCode;