import React, { useState, useEffect } from "react"
import Axios from "axios"
import Card from "./../components/Card"
import { Url } from './../constants/global';
import Chart from 'chart.js/auto';
import { Line } from "react-chartjs-2";

const ZipCodeByPrice = () => {
  const [cities,setCities] = useState();
  const [options,setOptions] = useState({
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Top 10 Zip Codes By Sales Price',
        color: "#444",
        font: {
          size:24,
        },
        padding: 20,
      },
      legend: {
        display: true,
        position: "bottom"
      },
    },

    maintainAspectRatio: false,
    aspectRatio: 1,
    interaction: {
      mode: 'index',
      intersect: false
    },
    showLabel: false,
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Zip Code'
        },
        grid: {
          display: false,
        },
        drawTicks: false,
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Price in Thousands'
        },
        position: 'left',
        grid: {
          drawBorder: false,
        }
      },
    }
  });
  const [chartData, setChartData] = useState({
    title: 'Top 10 Zip Codes By Pending Sales',
    labels: ['a','b','c','d','e','f','g','h','i','j'],
    datasets: [
      {
        data: [],
      },
      {
        data: [],
      }
  ]});

  const API_URL = Url + "/topZipByPrice";
  const axiosOptions = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  useEffect(() => {
    Axios.get(API_URL, axiosOptions)
      .then(data => {
        const dataArr = Object.values(data.data);
        const labels = dataArr.map(o => o.zipcode);
        const medianPrices = dataArr.map(o => o.median_price/1000);
        const medianListPrices = dataArr.map(o => o.median_list_price/1000);
        const cities = dataArr.map(o => o.city);
        setCities(cities);
        setChartData({labels: labels,
        datasets: [
         {
           label: "Sale Price",
           data: medianPrices,
           backgroundColor: [
             "rgba(75,192,192,1)",
             "#ecf0f1",
             "#50AF95",
             "#f3ba2f",
             "#2a71d0"
           ],
           borderColor: "#6b8abc",
           borderWidth: 2,
         },
         {
           label: "List Price",
           data: medianListPrices,
           backgroundColor: [
             "rgba(75,192,192,1)",
             "#ecf0f1",
             "#50AF95",
             "#f3ba2f",
             "#2a71d0"
           ],
           borderColor: "#544FC5",
           borderWidth: 2
         }
       ]});
       setOptions({
                      responsive: true,
                      plugins: {
                        title: {
                          display: true,
                          text: 'Top 10 Zip Codes By Sales Price',
                          color: "#444",
                          font: {
                            size:24,
                            family: "Monaco"
                          },
                          padding: 20,
                        },
                        legend: {
                          display: true,
                          position: "bottom"
                        },
                        tooltip: {
                          callbacks: {
                            footer: (footer) => {
                               return cities[footer[0].parsed.x];
                            }
                          }
                        }
                      },

                      maintainAspectRatio: false,
                      aspectRatio: 1,
                      interaction: {
                        mode: 'index',
                        intersect: false
                      },
                      showLabel: false,
                      scales: {
                        x: {
                          display: true,
                          title: {
                            display: true,
                            text: 'Zip Code'
                          },
                          grid: {
                            display: false,
                          },
                          drawTicks: false,
                        },
                        y: {
                          display: true,
                          title: {
                            display: true,
                            text: 'Price in Thousands'
                          },
                          position: 'left',
                          grid: {
                            drawBorder: false,
                          }
                        },
                      }
                    });
    })
  }, [])


  return (
    <Card>
      <div class="chart">
              <Line
                data={chartData}
                options={options}
              />
            </div>
    </Card>
  )
}

export default ZipCodeByPrice