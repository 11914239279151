import React, { useState } from 'react';
import axios from "axios";
import { Url } from './../constants/global';
import Card from './../components/Card';

const SignIn = () => {
    // State to store the form data
    const [userName,setUserName] = useState('');
    const [password,setPassword] = useState('');

    // Function to submit the form data using Axios
    const handleSubmit = async (e) => {
      e.preventDefault();
      let url = Url + "/signin";
      axios.post(url, {
        'userName': userName,
        'password': password,
      }, {
             headers: {
               'withCredentials': true,
               'credentials': 'include'
             }})
      .then(function (response) {
        sessionStorage.setItem("userObj",JSON.stringify(response.data));
        window.location = "/";
      })
      .catch(function (error) {
        alert("Invalid User Name or Password. Try again");
      });
    };

    return (
      <div class="centered-content">
      <div class="heading4"> </div><br/>
      <div class="signin-heading">Sign In</div><br/>
      <text>Enter user name and password to signin.</text><br/><br/>
      <form onSubmit={handleSubmit} class="signin-form">
        <div>
          <label>User Name <text class="label-required"></text></label>
          <input type="text" value={userName} required
            onChange={e => setUserName(e.target.value)} />
        </div><br/>
        <div>
          <label>Password <text class="label-required"></text></label>
          <input type="password"
            value={password} required
            onChange={e => setPassword(e.target.value)} />
        </div>
        <button type="submit" class="submit-button">Sign In</button>
      </form>
      <text>Forgot Password? <a href="/resetForm"> Click Here</a> to reset your password.</text>
    </div>
  );
}

export default SignIn;