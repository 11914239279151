import React,{ useState } from "react";
import Card from './../components/Card';
import Grid from './../components/Grid';
import CheckoutForm from './CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import { Url, stripePromise } from './../constants/global';

import {
  Elements
} from '@stripe/react-stripe-js';

const options = {
  mode: 'payment',
  amount: 1099,
  currency: 'usd',
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};

const DataSets = () => {

    const [price, setPrice] = useState(0);
    const [names, setNames] = useState();
    const [payment, setPayment] = useState(false);

    const handleCheck = async(e) => {
      var c = price;
      if(document.getElementById(e.target.id).checked)
        c += parseInt(document.getElementById(e.target.id).value);
      else
        c -= parseInt(document.getElementById(e.target.id).value);

      let check = "";
      for (let i = 0; i < 8; i++) {
        let id = "d" + i;
        if(document.getElementById(id).checked) {
          if(check.length === 0)
            check = id;
          else
            check += ":" + id;
        }
      }
      setNames(check);
      setPrice(c);
    }

    const handlePay = async (e) => {
      e.preventDefault();
      if(price > 0) {
          var answer = window.confirm("Payments are not refundable. Download starts once payment is confirmed." +
                       "Note: Make sure to sign in to retrieve your purchased data files for later retrieval.");
          if (answer) {
            setPayment(true);
          }
          else {
            //alert("Cancel the purchase");
          }
      } else {
        alert("Select data sets you would like to purchase.");
      }
    };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column tiny={12} small={8} medium={8} large={8}>
          <Card>
          <Card.Body isContentCentered hasPaddingHorizontal hasPaddingVertical>
            <form onSubmit={handlePay} class="signup-form">
              <text>Select all data sets you would like to purchase</text>
              <div>
                <input type="checkbox" id="d0" value="25" onClick={handleCheck} /> <label>Basic Population Details</label>
              </div>
              <div>
                <input type="checkbox" id="d1" value="50" onClick={handleCheck} /> <label>Education Levels</label>
              </div>
              <div>
                <input type="checkbox" id="d2" value="50" onClick={handleCheck}/> <label>Income Levels</label>
              </div>
              <div>
                <input type="checkbox" id="d3" value="25" onClick={handleCheck}/> <label>Housing Prices</label>
              </div>
              <div>
                <input type="checkbox" id="d4" value="100" onClick={handleCheck} /> <label>Business Profile</label>
              </div>
              <div>
                <input type="checkbox" id="d5" value="100" onClick={handleCheck}/> <label>Employment Profiles</label>
              </div>
              <div>
                 <input type="checkbox" id="d6" value="200" onClick={handleCheck}/> <label>School Enrollment</label>
              </div>
              <div>
                <input type="checkbox" id="d7" value="200" onClick={handleCheck}/> <label>Household Types</label>
              </div>
              <div>
                Total Price: {price} &nbsp;&nbsp; <button type="submit" class="pay-button">Pay</button>
              </div>
            </form>
            <br/>
            <div class="text-style">
            Sample Files: <a href="/sampleFiles">Sample Files</a>
            <h3>Questions? Prefer something custom ? </h3>
            <h4><a href= "mailto: support@realyzeai.com">Email Us</a> with your requirements. We will get back to you a price quote.</h4>
            </div>
            <div class="footnotes"> Make sure to signup & login to refer your file back. <br/>
            Review our <a href="/privacy">Privacy Statement</a></div>
          </Card.Body>
          </Card>
        </Grid.Column>
        <Grid.Column tiny={12} small={4} medium={4} large={4}>
          {payment ?
            <Card>
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm datasets={names} />
              </Elements>
            </Card>  : <div />
          }
        </Grid.Column>
      </Grid.Row>
    </Grid>

  );
}

export default DataSets;