import React from 'react';
import Card from './../components/Card';
import { Twitter, LinkedIn } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';


const Footer = () => {
  return (
    <footer class="footer">
      <div class="footer-text"><a href= "mailto: support@realyzeai.com">Contact Us</a>&nbsp;&nbsp;
      <a href= "/privacy">Privacy Statement</a>&nbsp;&nbsp;
      Follow Us:<span class="icon-align">
      <a href= "https://www.linkedin.com/company/realyzeai"><LinkedInIcon /></a>
      <a href= "https://x.com/realyze_ai"><TwitterIcon /></a></span></div>
    </footer>
  );
}

export default Footer;