import React from 'react';
import './../App.css';
import { useViewport } from 'react-viewport-hooks';

import ZipCodeByPrice from './ZipCodeByPrice';
import ZipCodeByHomesSold from './ZipCodeByHomesSold';
import ZipCodeByPendingSales from './ZipCodeByPendingSales';
import FindByZipCode from './FindByZipCode';
import Card from './../components/Card';
import Grid from './../components/Grid';
import ChatWindow from './ChatWindow';

const GridLayout = () => {

    return (
        <Grid>
          <Grid.Row>
            <Grid.Column tiny={12} small={4} medium={8} large={8}>
              <br/>
              <Grid.Row>
                <Grid.Column tiny={12}><FindByZipCode /></Grid.Column>
              </Grid.Row>
              <br />
              <Grid.Row className="graphs-size">
                <Grid.Column tiny={4}><ZipCodeByPendingSales /></Grid.Column>
                <Grid.Column tiny={4}><ZipCodeByHomesSold /></Grid.Column>
                <Grid.Column tiny={4}><ZipCodeByPrice /></Grid.Column>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column tiny={12} small={8} medium={4} large={4}>
              <br/>
              <ChatWindow />
            </Grid.Column>
          </Grid.Row>
        </Grid>

    );
};

const ListLayout = () => {

    return (
        <Grid>
              <Grid.Row>
                <Grid.Column tiny={12}><Card><FindByZipCode /></Card></Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column tiny={12}><ChatWindow /></Grid.Column>
              </Grid.Row>
              <Grid.Row>
                  <Grid.Column><ZipCodeByPendingSales /></Grid.Column>
                  <Grid.Column><ZipCodeByHomesSold /></Grid.Column>
                  <Grid.Column><ZipCodeByPrice /></Grid.Column>
              </Grid.Row>
        </Grid>
    );
};

const Overview = () => {

        const { vw } = useViewport();
        console.log(vw);

        let layout = 'grid';
        if (vw < 768) {
            layout = 'list';
        }
        return (
            <div>
                {layout === 'grid' ?
                    <GridLayout /> :
                    <ListLayout />}
            </div>
        );
}

export default Overview;