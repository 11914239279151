import React, { useState } from "react";
import Chart from 'chart.js/auto';
import { Line } from "react-chartjs-2";

const ZipCodePriceDetails = (props) => {

    const single = props.data.filter(o => o.period_end != null && o.property_type_id === 6);
    const all = props.data.filter(o => o.period_end != null && o.property_type_id === -1);
    const town = props.data.filter(o => o.period_end != null && o.property_type_id === 13);
    const condo = props.data.filter(o => o.period_end != null && o.property_type_id === 3);
    const multi = props.data.filter(o => o.period_end != null && o.property_type_id === 4);

    const options = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: 'Median Home Sale Price',
          color: "#444",
          font: {
            size:24,
            family: "Monaco"
          },
          padding: 20,
        },
        legend: {
          display: true,
          position: "bottom"
        },
      },
      maintainAspectRatio: false,
      aspectRatio: 1,
      interaction: {
        mode: 'index',
        intersect: false
      },
      showLabel: false,
      scales: {
        x: {
          display: true,
          title: {
            display: true,
            text: 'Zip Code'
          },
          grid: {
            display: false,
          },
          drawTicks: false,
        },
        y: {
          display: true,
          title: {
            display: true,
            text: 'Price in Thousands'
          },
          position: 'left',
          grid: {
            drawBorder: false,
          }
        },
      }
    };

    const singleData =  {
        labels: all.map(o => o.period_end != null && o.period_end.split("T")[0]).sort(),
        datasets: [
          {
            label: 'Single Family Home',
            data: single.map(o => o.period_end != null && o.median_price/1000),
            borderColor: "#058DC7",
          },
          {
            label: 'Condo',
            data: condo.map(o => o.period_end != null && o.median_price/1000),
            borderColor: "#FF9655",
          },
          {
            label: 'Town Home',
            data: town.map(o => o.period_end != null && o.median_price/1000),
            borderColor: "#DDDF00",
          },
          {
            label: 'Multi Family Home (2-4 Units)',
            data: multi.map(o => o.period_end != null && o.median_price/1000),
            borderColor: "#24CBE5",
          },
          {
            label: 'All Home Types',
            data: all.map(o => o.period_end != null && o.median_price/1000),
            borderColor: "#64E572",
          }
        ]
    };

    return (
      <div class="find-chart"><Line data={singleData} options={options} /></div>
    );
};

export default ZipCodePriceDetails;