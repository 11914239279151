import React, { useState, useEffect } from "react"
import Axios from "axios"
import Card from './../components/Card'
import { Url } from './../constants/global';
import Chart from 'chart.js/auto';
import { Line } from "react-chartjs-2";

const ZipCodeByPendingSales = () => {
  const [cities, setCities] = useState([]);

  const [options,setOptions] = useState({
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: 'Top 10 Zip Codes By Pending Sales',
          color: "#444",
          font: {
            size:24,
          },
          padding: 20,
        },
        legend: {
          display: false
        },
      },

      maintainAspectRatio: false,
      aspectRatio: 1,
      interaction: {
        mode: 'index',
        intersect: false
      },
      showLabel: false,
      scales: {
        x: {
          display: true,
          title: {
            display: true,
            text: 'Zip Code'
          },
          grid: {
            display: false,

          },
          drawTicks: false,
        },
        y: {
          display: true,
          title: {
            display: true,
            text: 'Number of Pending Sales'
          },
          grid: {
            drawBorder: false,
          }
        }
      }
    });
    const [chartData, setChartData] = useState({
          title: 'Top 10 Zip Codes By Pending Sales',
          labels: ['a','b','c','d','e','f','g','h','i','j'],
          datasets: [
            {
              data: [],
              backgroundColor: [
                "rgba(75,192,192,1)",
                "#ecf0f1",
                "#50AF95",
                "#f3ba2f",
                "#2a71d0"
              ],
              //borderColor: "black",
              borderWidth: 2,
              borderColor: "rgb(186, 231, 85)",
            }
          ]});

  const API_URL = Url + "/topZipByPendingSales";
  const axiosOptions = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

    useEffect(() => {
      Axios.get(API_URL, axiosOptions)
        .then(data => {
          const dataArr = Object.values(data.data);
          const labels = dataArr.map(o => o.zipcode);
          const values = dataArr.map(o => o.pending_sales);
          const cities = dataArr.map(o => o.city);
          setCities(cities);
          setChartData({
             labels: labels,
             datasets: [
               {
                 data: values,
                 backgroundColor: [
                   "rgba(75,192,192,1)",
                   "#ecf0f1",
                   "#50AF95",
                   "#f3ba2f",
                   "#2a71d0"
                 ],
                 borderColor: "#544FC5",
                 borderWidth: 2
               },
             ]});
          setOptions({
               responsive: true,
               plugins: {
                 title: {
                   display: true,
                   text: 'Top 10 Zip Codes By Pending Sales',
                   color: "#444",
                   font: {
                     size:24,
                     family: "Monaco"
                   },
                   padding: 20,
                 },
                 legend: {
                   display: false
                 },
                 tooltip: {
                   callbacks: {
                     footer: (footer) => {
                       return cities[footer[0].parsed.x];
                     }
                   }
                 }
               },

               maintainAspectRatio: false,
               aspectRatio: 1,
               interaction: {
                 mode: 'index',
                 intersect: false
               },
               showLabel: false,
               scales: {
                 x: {
                   display: true,
                   title: {
                     display: true,
                     text: 'Zip Code'
                   },
                   grid: {
                     display: false,

                   },
                   drawTicks: false,
                 },
                 y: {
                   display: true,
                   title: {
                     display: true,
                     text: 'Number of Pending Sales'
                   },
                   grid: {
                     drawBorder: false,
                   }
                 }
               }
             });
        })
    }, [])

  return (
    <Card>
      <div class="chart"><Line data={chartData} options={options} /></div>
    </Card>
  )
}

export default ZipCodeByPendingSales