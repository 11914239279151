import React, { useState } from 'react';
import axios from "axios";
import { Url } from './../constants/global';

const ResetForm = () => {
    // State to store the form data
    const [userName,setUserName] = useState('');
    const [email,setEmail] = useState('');

    // Function to submit the form data using Axios
    const handleSubmit = async (e) => {
      e.preventDefault();
      if(!userName && !email) {
        alert("Enter user name or email to proceed");
      } else {
        let url = Url + "/resetForm";
        axios.get(url, {
          params: {
            'userName': userName,
            'email': email,
          }
        })
        .then(function (response) {
          alert("Check your email for instructions on how to reset your password.");
        })
        .catch(function (error) {
          alert("User Name or Email given is not present in system. Please try again.")
        });
      }
    };

    return (
      <div class="width-centered">
        <div class="heading2">
          Reset your password by entering email or user name <br/>
        </div>
        <form onSubmit={handleSubmit}>
          <br/><br/>
          <div class="form-group">
            <label>User Name</label>
            <input type="text" value={userName}
              onChange={e => setUserName(e.target.value)}
              className="form-control" placeholder="User Name" />
          </div>
          OR
          <br/><br/>
          <div class="form-group">
            <label>Email</label>
            <input type="email" value={email}
              onChange={e => setEmail(e.target.value)}
              className="form-control" placeholder="Email" />
          </div>
          <button type="submit" class="submit-button">Reset</button>
      </form>
    </div>
  );
}

export default ResetForm;