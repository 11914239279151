import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {loadStripe} from '@stripe/stripe-js';
import axios from "axios";
import { Url } from './../constants/global';
import { CircularProgress } from '@mui/material';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

type Props = {
  names: string;
};

const CheckoutForm = (props: Props)  => {
  const stripe = useStripe();
  const elements = useElements();
  const [names, setNames] = useState(props.datasets);
  const [errorMessage, setErrorMessage] = useState(null);
  const [spinnerCSS, setSpinnerCSS] = useState("non-visible");

  const handleSubmit = async (event) => {
    event.preventDefault();
    //alert(props.datasets);
    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    const isLoggedIn = sessionStorage.getItem("userObj");

    let userId = "";
    if(isLoggedIn)
      userId = JSON.parse(isLoggedIn).userId;

    setSpinnerCSS("visible");
    let create_url = Url + "/createIntent?userId=" + userId + "&datasets=" + props.datasets;
    let confirm_url = Url + "/confirmIntent";
    const response = await axios.get(create_url);
    const clientSecret = response.data.client_secret;
    const paymentId = response.data.paymentId;

    let filename = props.datasets.includes(":") ?'data.zip':'data.csv';

    await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: 'http://www.realyzeai.com/confirmPayment?paymentId=' + response.data.paymentId,
      },
      redirect: "if_required",
    }).then(function(result) {
         if (result.error) {
           alert("There is an error processing payment. Try again");
         }
         if(result.paymentIntent) {
           try {
             axios.get(confirm_url, {
               params: {
                 'paymentId': paymentId
               },
               responseType: 'blob', // important
             }).then((response) => {
               alert("Your Payment Id: "+ paymentId + " store for your records. Click OK to start download");
               setSpinnerCSS("non-visible");
               const url = window.URL.createObjectURL(new Blob([response.data]));
               const link = document.createElement('a');
               link.href = url;
               link.setAttribute('download', filename);
               document.body.appendChild(link);
               link.click();
               link.remove();
             });
           } catch (error) {
             console.error("Error during Get: ", error);
           }
         }
    });
  };

  return (
    <div>
    <div class={spinnerCSS}>
      <CircularProgress />
    </div>
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <br />
      <button type="submit" disabled={!stripe || !elements}>
        Pay
      </button>
      <div class="footnotes"><br />
      Disclaimer: While we do extensively validate data, financial decisions do involve risk and use judgement with the data.
      Payment is non-refundable. If you have any trouble with downloading a file - <a href= "mailto: support@realyzeai.com"> Email Us </a> </div>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
    </div>
  );
};

export default CheckoutForm;