import {loadStripe} from '@stripe/stripe-js';

// Local Development
// const Url = "http://localhost:8080"
// const stripePromise = loadStripe('pk_test_51PIIfPLvy873yznsqHI70EMYFVz4gbTPKOTTOqrjsQvZKyqBuIPtsMB8b0IR5wGHrKpRh2miFYKM5iAajyF8eI2n00QI027lY2');

// Production
const Url = "https://realyzeapi.azurewebsites.net"
const stripePromise = loadStripe('pk_live_51PIIfPLvy873yznsT6ba2mDKCIxuYgRYGJ3uc26bHyVcUy6Q4AqxxDjjXGRjy8ch5Uhm4tTj5gkfTy6bVmFdKv6D006txUKm1D');

export { Url, stripePromise }