import React from 'react';
import Card from './../components/Card';

const About = () => {
  return (
    <Card>
      <div class="heading">
          <h3>Mission</h3>
      </div>
      <div>
          <p>Welcome to Realyze AI, your destination for zip code analytics and market intelligence. Our mission is to empower real estate professionals, investors, small business owners, and homebuyers with the data and insights they need to make informed decisions in an ever-changing market.</p>
      </div>
      <div class="heading2">
          <h3>Who We Are</h3>
      </div>
      <div>
          <p>Realyze AI was founded by a team of passionate data analytical experts and tech enthusiasts. Drawing from our experience, we identified a critical need for a comprehensive platform that seamlessly integrates robust data analytics with user-friendly tools. Our mission is to transform complex market data into actionable insights, making them accessible to everyone, from seasoned investors to first-time homebuyers.</p>
      </div>
      <div class="heading2">
          <h3>What We Do</h3>
      </div>
      <div>
          <p>At Realyze AI, we provide advanced analytics designed to help you understand market trends, demographics, and make data-driven decisions. Our platform offers:</p>
          <ul>
              <li>Market Analysis: Stay ahead of the curve with real-time market trends, price fluctuations, and economic indicators.</li>
              <li>Custom Reports: Generate detailed reports tailored to your specific needs, whether you’re analyzing a single property or an entire portfolio.</li>
              <li>Demographic Profiles: Provide demographic, social, and economic profiles by zip code to predict growth in an area.</li>
          </ul>
      </div>
      <div class="heading2">
          <h3>Our Approach</h3>
      </div>
      <div>
          <p>We believe in the power of data to transform the real estate industry. Our approach is built on three core principles:</p>
          <ul>
              <li>Accuracy: We source data from reliable and diverse channels to ensure our analytics are precise and up-to-date.</li>
              <li>Transparency: We provide clear, easy-to-understand insights without the jargon, making it simple for you to act on our data.</li>
              <li>Innovation: We continuously evolve our platform, integrating the latest technology and analytical methods to stay at the forefront of the industry.</li>
          </ul>
      </div>
      <div class="heading2">
          <h3>Why Choose Us?</h3>
      </div>
      <div>
          <ul>
              <li>Comprehensive Data: Access a vast database of demographics, social and economic profiles, real estate information, including historical trends, current market conditions, and predictive analytics.</li>
              <li>Natural Language Capabilities: Utilize our advanced natural language processing capabilities for analyzing data.</li>
              <li>User-Friendly Interface: Navigate our platform with ease, thanks to intuitive design and customizable features.</li>
          </ul>
      </div>
      <div class="heading2">
          <h3>Get in Touch</h3>
      </div>
      <div>
          <p>Ready to transform your investment journey with powerful analytics? Contact us today to learn more about our insights which can help you achieve your goals.</p>
          <p><a href="mailto:support@realyzeai.com">Email Us</a></p>
      </div>

    </Card>
  );
}

export default About;