import Card from './../components/Card';

const Privacy = () => {
  return (
    <div class="section-border">
      <div class="heading">
          Privacy Statement
      </div>
      <div class="heading2">
          <br/>
          Web Page Search Results
      </div>
      <div>
          <p>We do not impose any restrictions on the search results from web pages. You are free to access and utilize these search results as per your needs.</p>
      </div>
      <div class="heading2">
          Purchased Data Sets
      </div>
      <div>
          <p>For any data sets purchased through our services, sharing or redistribution of this data is strictly prohibited. This ensures the protection of proprietary information and compliance with licensing agreements.</p>
      </div>
      <div class="heading2">
          Credit Card Processing
      </div>
      <div>
          <p> We do not store any credit card information in our records.
          Look at <a href="https://stripe.com/privacy">Stripe Privacy Policy</a> for more details.</p>
      </div>
    </div>
  );
}

export default Privacy;