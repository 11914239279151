import React from 'react';
import Card from './../../components/Card';

const Spotlight3 = () => {
  return (
    <Card>
      <div><h2 style={{color: "blue"}}>Spotlight on Myrtle Beach, SC: Coastal Gem in South Carolina</h2>

      Myrtle Beach has always been a popular destination due to its coastal charm and flourishing tourism. In recent years, it has also attracted significant interest from homebuyers and investors. The housing market data from September 2023 to August 2024 provides valuable insights into the trends shaping this vibrant real estate landscape.

       In this article, we'll dive into the sales data, price fluctuations, and key population demographics to offer a full picture of the Myrtle Beach housing market.

       <h3 style={{color: "blue"}}>Population and Demographics</h3>
       The city of Myrtle Beach is home to 36,064 residents, with 8,920 (about 25%) having attained a bachelor's degree or higher. The education level of the population reflects the city's steady growth and the type of professionals attracted to the area.<br/><br/>

       <b>Income levels are varied:</b> <br/>
       Mean income: $80,513<br/>
       Median income: $50,500<br/><br/>

       These figures indicate that while Myrtle Beach is an attractive destination for high earners, it remains accessible for those with more moderate incomes, supporting a balanced real estate market.

       <h3 style={{color: "blue"}}>Real Estate Trends from September 2023 to August 2024</h3>
       The charts below captures the key metrics in Myrtle Beach's housing market over a year, with both the number of homes sold and median prices represented.<br/><br/>
       <img src={require('./../../assets/images/myrtle1.jpg')} style={{padding: 3}} class="spotlightimg" alt="Myrtle Beach, SC" width="400" height="300"/>
       <img src={require('./../../assets/images/myrtle2.jpg')} style={{padding: 3}} class="spotlightimg" alt="Myrtle Beach, SC" width="400" height="300"/>

       <h4 style={{color: "purple"}}>Fall 2023: High Sales, Mixed Prices</h4>
       <b>October 2023:</b>
       Home sales slightly decreased to 625 homes, while the median price was $350,000. This was a stable performance reflecting high demand, aligning with Myrtle Beach's continued appeal to buyers.<br/><br/>
       <b>November 2023:</b>
       The number of homes sold remained high at 608, though the median price dropped slightly to $337,995, possibly indicating a shift towards more affordable home options as the market adjusted to seasonality.

       <h4 style={{color: "purple"}}>Winter 2023-2024: Market Adjustments</h4>
       <b>December 2023:</b>
       In December, 573 homes were sold, with the median price dipping to $339,900. This reduction in sales and prices was consistent with the holiday season, when market activity tends to slow down.<br/><br/>
       <b>January 2024:</b>
       The new year brought slight stabilization, with 548 homes sold with above same sale price as last period. Despite the winter slowdown, Myrtle Beach maintained a steady level of transactions.<br/><br/>
       <b>February 2024:</b>
       Home sales dipped to 525, but the median price rose to $340,000, indicating that buyer interest remained high despite fewer transactions.

       <h4 style={{color: "purple"}}>Spring 2024: Strong Rebound</h4>

       <b>March 2024:</b>
       Spring saw an uptick, with 538 homes sold and the median price climbing to $350,000. This marked the beginning of a seasonal resurgence as more buyers entered the market.<br/><br/>
       <b>April 2024:</b>
       The market continued to strengthen, with 563 homes sold and a median price of $359,000, showing healthy growth in both sales volume and prices.<br/><br/>
       <b>May 2024:</b>
       Myrtle Beach experienced a peak in home sales with 592 homes sold and the median price jumping to $369,585. This growth reflects increased demand for homes as the city’s appeal continued to rise.

       <h4 style={{color: "purple"}}>Summer 2024: Consistent Sales and High Prices</h4>

       <b>June 2024:</b>
       The trend remained strong, with 592 homes sold and a median price of $369,995, reflecting sustained interest in the real estate market.<br/><br/>
       <b>July 2024:</b>
       Sales dipped slightly to 582 homes, while the median price climbed further to $371,606, marking the highest recorded price in this period.<br/><br/>
       <b>August 2024:</b>
       The summer ended with 612 homes sold and a slight decline in the median price to $364,500. Despite the small decrease, the overall market remained stable and active.

       <h4 style={{color: "blue"}}>Key Takeaways</h4>
       <b>Resilient Housing Demand:</b> The number of homes sold remained strong throughout the year, with sales peaking in May and remaining stable through summer.<br/><br/>
       <b>Gradual Price Increases:</b> Median prices generally trended upward, reflecting both seasonal demand and the appeal of Myrtle Beach as a prime coastal destination. Prices hit their highest point in July 2024, with a median price of $371,606.<br/><br/>
       <b>Population Influence:</b> With a significant portion of the population being college-educated and income levels varying from moderate to high, Myrtle Beach attracts a broad spectrum of homebuyers, from professionals to retirees.

       <h4 style={{color: "blue"}}>Conclusion</h4>
       Myrtle Beach has demonstrated consistent strength in its housing market from fall 2023 to summer 2024. Despite some seasonal fluctuations, both home sales and prices have shown overall growth. With its balanced demographics, strong economy, and enduring appeal as a coastal city, Myrtle Beach is expected to continue its real estate growth trajectory well into 2025.
       For anyone considering buying a home or investing in real estate, Myrtle Beach offers both steady demand and price growth, making it an attractive market for a range of buyers.
      </div>
    </Card>
  );
}

export default Spotlight3;





