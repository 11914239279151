import React from 'react';
import Card from './../../components/Card';
import LockIcon from '@mui/icons-material/Lock';

const TopCondoPriceIncreases = () => {
  return (
    <Card>
      <div class="heading3">
          <h3>Zip Codes with highest percentage Condo/Co-Op median price surge </h3>
      </div>
      <div>
          <p>
            The U.S. real estate market has experienced significant shifts over the past year, with certain areas seeing notable increases in condo prices. Analyzing data from January 2023 to July 2024, we've pinpointed zip codes where condo prices have surged the most, highlighting areas that have become increasingly attractive for real estate investment. To ensure the accuracy of our analysis, we have only included zip codes where at least five condo sales have occurred in a month, minimizing the risk of data skew from isolated high or low sales.

            <h4>10. 43440 - Lakeside Marblehead, OH - 68.87% Increase </h4>
            The condo market in Lakeside Marblehead has seen a significant price increase, reflecting a strong demand and potentially a limited supply, which is driving prices upward.
            <h4>9. 48162 - Monroe, MI - 72.61% Increase</h4>
            Monroe has experienced a notable surge, possibly due to emerging developments or increased interest from buyers seeking affordable alternatives in the region.
            <h4>8. 32502 - Pensacola, FL - 81.24% Increase</h4>
            With its proximity to desirable amenities and possibly a growing tourism or second-home market, Pensacola has seen a substantial increase in condo prices.
            <h4>7. 44904 - Lexington, OH - 83.30% Increase</h4>
            Lexington has become a hot spot for condo buyers, likely due to a combination of factors such as local economic growth and improved infrastructure.

            <h4><LockIcon /></h4>
            For detailed data on these and other zip codes, including historical trends and insights to guide your real estate investment decisions, purchase our comprehensive <a href="https://www.realyzeai.com/datasets">data sets</a>.
            OR <a href="https://www.realyzeai.com/subscribe">subscribe</a>. These resources offer detailed data across all zip codes to help you make informed choices in today’s dynamic market.
          </p>
      </div>
    </Card>
  );
}

export default TopCondoPriceIncreases;