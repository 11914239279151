import React, { useState } from 'react';
import axios from "axios";
import { Url } from './../constants/global';

const ResetPassword = () => {
    // State to store the form data
    const [password,setPassword] = useState('');

    // Function to submit the form data using Axios
    const handleSubmit = async (e) => {
      e.preventDefault();
      const queryParams = new URLSearchParams(window.location.search);
      const value = queryParams.get('resetToken');
      let url = Url + "/resetPassword";
      axios.get(url, {
        params: {
          'password': password,
          'token': value
        }
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    };

    return (
      <div class="centered">
        <div class="heading">Enter New Password: </div>
          <form onSubmit={handleSubmit}>
            <input type="password" className="form-control"
                    value={password} required
                    pattern="(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                    onChange={e => setPassword(e.target.value)} />
            <button type="submit" class="submit-button">Go</button>
          </form>
      </div>
  );
}

export default ResetPassword;