import React from 'react';
import ReactDOM from 'react-dom';
import axios from "axios";

const ConfirmPayment = () => {

  return (
    <div>
      alert("Payment Confirmed. Download will begin shortly, do not close this window.");
    </div>
  );
};

export default ConfirmPayment;