import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {loadStripe} from '@stripe/stripe-js';
import axios from "axios";
import { Url } from './../constants/global';
import Card from './../components/Card';
import { CircularProgress } from '@mui/material';

import {
  PaymentElement,
  Elements,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';

type Props = {
  names: string;
};

const Subscribe = (props: Props)  => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  let userObj = sessionStorage.getItem("userObj");

  let userEmail, userSubscriptionId;
  if(userObj != null) {
    userEmail = JSON.parse(userObj).email;
    userSubscriptionId = JSON.parse(userObj).subscriptionId;
  }

  const [email,setEmail] = useState(userEmail);
  const [spinner,setSpinner] = useState(false);
  const [formCSS,setFormCSS] = useState("visible");
  const [spinnerCSS,setSpinnerCSS] = useState("non-visible");
  const [subscriptionId,setSubscriptionId] = useState(userSubscriptionId);

  const handleCheck = async(event) => {
     event.preventDefault();
     let userEmail = document.getElementById("email").value;

     if(userEmail != null) {
       let url = Url + "/subscriptions?email=" + userEmail;
       const response = await axios.get(url);
       if(response.data) {
         setSubscriptionId(response.data);
       } else {
         setSubscriptionId(null);
       }
     }
  }

  const handleCancel = async(event) => {

     let userEmail = document.getElementById("email").value;
     if(userEmail != null) {
       let url = Url + "/cancelSubscription?email=" + userEmail;
       const response = await axios.get(url);
       if(response) {
         alert("Subscription cancelled successfully.");
         window.location = "/";
       } else {
         alert("Error in cancelling subscription. Try again or Contact Us");
       }
     }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    //alert(props.datasets);
    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    const isLoggedIn = sessionStorage.getItem("userObj");

    let userId = "";
    if(isLoggedIn) {
      userId = JSON.parse(isLoggedIn).userId;
    }

    setEmail(document.getElementById("email").value);

    let userEmail = email;
    if(document.getElementById("email").value != userEmail) {
      userEmail = document.getElementById("email").value;
    }


    let type = "week";
    if(document.getElementById("month").checked)
      type = "month"

    setSpinnerCSS("visible centered-content");
    setFormCSS("non-visible");

    let create_url = Url + "/createSubscriptionIntent?" +
                     "userId=" + userId + "&type=" + type +
                     "&email=" + userEmail;

    let confirm_url = Url + "/confirmSubscriptionIntent";
    const response = await axios.get(create_url);
    const clientSecret = response.data.clientSecret;
    const subscriptionId = response.data.subscriptionId;

    await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: Url + '/confirmSubscriptionIntent?subscriptionId=' + subscriptionId,
      },
      redirect: "if_required",
      setup_future_usage: 'off_session'
    }).then(function(result) {
         if (result.error) {
           setFormCSS("visible");
           setSpinnerCSS("non-visible");
           alert(result.error);//"There is an error processing payment. Try again");
         } else {
           try {
             axios.get(confirm_url, {
               params: {
                 'subscriptionId': subscriptionId,
                 'payment_intent': result.paymentIntent.id,
               },
             }).then((response) => {
               setSpinnerCSS("non-visible");
               setFormCSS("visible");
               alert("Subscription Successful.");
               window.location = "/";
             });
           } catch (error) {
               console.error("Error during Payment: ", error);
           }
         }
    });
  };

  return (
    <div>
    <div class={spinnerCSS}>
      <CircularProgress />
    </div>
    <div class={formCSS}><form onSubmit={handleSubmit} class="centered-content signup-form">
      <div class="heading3"> Subscription Advantages:</div>
      <div>** Weekly newsletter with insights into real estate market.<br/>
      ** Projections using our advanced analytic techniques<br/>
      ** We tailor contents to meet your analysis needs.<br/></div>
      <div class="heading3">Please select your preferred billing option method:<br/></div>
        <div>
          <input type="radio" id="week" name="type" value="week" checked/>
          <label for="week">Weekly Billing ($2 per week) </label><br/><br/>

          <input type="radio" id="month" name="type" value="month" />
          <label for="month">Monthly Billing ($5 per month) </label>
      </div>
      { spinner ? <CircularProgress /> : '' }
      <div>
          Email: <input type="email" value={email} id="email" required />&nbsp;
          { subscriptionId != null ? '' : <input type="button" value="Check Subscription" onClick={handleCheck} />}
          <hr />
        </div>
        { subscriptionId != null ? <div class="heading4">You are already subscribed - <input type="button" value="Cancel Subscription" onClick={handleCancel} /></div> :
        <div><PaymentElement />
        <div>
          <button type="submit">Submit</button>
        </div></div>}
        <div class="footnotes">
        ** Check our <a href="https://www.realyzeai.com/privacy">Privacy Policy</a> about your card data.
      </div>
    </form></div>
    </div>
  );
};

export default Subscribe;