import './Navigation.css';
import { FaBars } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const Navigation = () => {

     const isLoggedIn = sessionStorage.getItem("userObj");

     const handleClick = async (e) => {
          var x = document.getElementById("myTopnav");
          if (x.className === "topnav") {
            x.className += " responsive";
          } else {
            x.className = "topnav";
          }
     };

     const handleLogout = async (e) => {
          sessionStorage.removeItem("userObj");
          window.location = "/";
     };


     let display;
     if (isLoggedIn != null) {
       display = JSON.parse(isLoggedIn).username;
     }

     return (
        <div class="topnav" id="myTopnav">
          <img src={require('./../assets/images/logo.png')} style={{padding: 3}} class="logoimg" alt="Realyze AI" width="200" height="40"/>
          {isLoggedIn ?
            <NavLink onClick={handleLogout}>Log Out</NavLink>
            :
            <NavLink activeStyle={{ color:'#5754a8' }} to="/signin">Sign In</NavLink>
          }
          <NavLink activeStyle={{ color:'#5754a8' }} to="/subscribe" >Subscribe</NavLink>
          {isLoggedIn ? <a class="username">{display}</a> : ''}
          {isLoggedIn ?
            <NavLink activeStyle={{ color:'#5754a8' }} to="/purchases"> My Purchases</NavLink> : '' }
          {isLoggedIn ?
            '' :
            <NavLink activeStyle={{ color:'#5754a8' }} to="/signup">Sign Up</NavLink>
          }
          <NavLink activeStyle={{ color:'#5754a8' }} to="/spotlight" >Sp<img src={require('./../assets/images/Spotlight.png')} class="spotlight" width="12" height="12"/>tlight</NavLink>
          <NavLink activeStyle={{ color:'#5754a8' }} to="/news" >News</NavLink>
          <NavLink activeStyle={{ color:'#5754a8' }} to="/dataSets">Data Sets</NavLink>
          <NavLink activeStyle={{ color:'#5754a8' }} to="/about" >About</NavLink>
          <NavLink activeStyle={{ color:'#5754a8' }} to="/" >Home</NavLink>
          <a class="icon" onClick={handleClick}>
            <FaBars/>
          </a>
        </div>
     );
}

export default Navigation;