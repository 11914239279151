// App.js
import React from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Overview from "./views/Overview";
import About from "./views/About";
import DataSets from "./views/DataSets";
import SignUp from "./views/SignUp";
import SignIn from "./views/SignIn";
import ResetForm from "./views/ResetForm";
import ResetPassword from "./views/ResetPassword";
import LogOut from "./views/LogOut";
import ConfirmPayment from "./views/ConfirmPayment"
import SampleFiles from "./views/SampleFiles"
import Purchases from "./views/Purchases"
//import Navbar from "./Navbar/Navbar";
import Privacy from "./views/Privacy";
import News from "./views/News";
import Spotlight from "./views/Spotlight";
import Navigation from "./Navigation/Navigation"
import SubscribeForm from "./views/SubscribeForm";
import Footer from "./views/Footer";

class App extends React.Component {
  render() {
    return (
      <Router>
          <Navigation />
          <Routes>
              <Route path="/" element={<Overview />} />
              <Route path="/about" element={<About />} />
              <Route path="/dataSets" element={<DataSets />} />
              <Route path="/confirmPayment/:paymentId" element={<ConfirmPayment />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/resetForm" element={<ResetForm />} />
              <Route path="/logout" element={<LogOut />} />
              <Route path="/resetPassword" element={<ResetPassword />} />
              <Route path="/sampleFiles" element={<SampleFiles />} />
              <Route path="/purchases" element={<Purchases />} />
              <Route path="/subscribe" element={<SubscribeForm />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/News" element={<News />} />
              <Route path="/spotlight" element={<Spotlight />} />
          </Routes>
          <Footer />
      </Router>
    );
  }
}

export default App;
